import React from 'react'
import qs from 'query-string'
import dynamic from 'next/dynamic'
import { get, headers } from '../../lib/fetch'
import { CheckoutAddressConsumer } from '../CheckoutAddressProvider/CheckoutAddressProvider'
const FpHelloBar = dynamic(() => import('../FpHelloBar/FpHelloBar'))
import GlobalContext from '../GlobalContext'
import { getUserType } from '../../utils/userType'

class DeliverySlotHelloBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      shouldShow: false,
      loading: false,
      isPositive: true,
    }

    this.getDeliverySlotInfo = this.getDeliverySlotInfo.bind(this)
  }

  /**
   * Executed only if feature enabled in remote config (deliverySlotConfig key)
   * Calls slot-availability API to get slot availability info
   * Called conditionally only for homepage at the moment in Layout.jsx
   *
   * Conditions:
   * 1. Slots available = don't show
   * 2. Slots unavailable = show with message from remote config
   * 3. API failure = show with error message
   */
  getDeliverySlotInfo() {
    const { checkoutAddress } = this.props
    const { storeId, address, location, pincode } = checkoutAddress || {}
    // eslint-disable-next-line no-useless-escape
    const deliverySlotConfigObj = {
      'isEnabledOnHomePage': '1',
      'isEnabledOnCartPage': '1',
      'displayText':
        " Sorry, our delivery slots are full, but don't worry! We add new slots daily. Please check back soon. ",
    }
    if (deliverySlotConfigObj?.isEnabledOnHomePage === '1') {
      this.setState(
        {
          loading: true,
        },
        async () => {
          const queryParams = qs.stringify({
            storeId: storeId,
            orderType: getUserType() === 'B2B' ? 'B2B' : 'DELIVERY',
            'address[address]': address,
            'address[latitude]': location?.latitude,
            'address[longitude]': location?.longitude,
            'address[pincode]': pincode,
          })
          const response = await get(`slot-availability?${queryParams}`, {
            headers: headers(),
          }).catch(() => {
            this.setState({
              loading: false,
              shouldShow: false,
            })
            return
          })

          if (!response || !response?.data) {
            this.setState({
              loading: false,
              shouldShow: false,
            })
          } else if (!response?.data?.available) {
            this.setState({
              loading: false,
              isPositive: false,
              text: deliverySlotConfigObj.displayText,
              shouldShow: true,
            })
          } else {
            this.setState({
              loading: false,
              shouldShow: false,
            })
          }
        }
      )
    } else {
      this.setState({ shouldShow: false })
    }
  }

  componentDidUpdate(prevProps) {
    const { checkoutAddress } = this.props

    if (
      prevProps.checkoutAddress.storeId !== checkoutAddress.storeId ||
      (prevProps.checkoutAddress.pincode !== checkoutAddress.pincode &&
        checkoutAddress.pincode)
    ) {
      this.getDeliverySlotInfo()
    }
  }

  componentDidMount() {
    if (this.props.checkoutAddress?.address) {
      this.getDeliverySlotInfo()
    }
  }

  render() {
    const { shouldShow } = this.state
    const { loading, isPositive, text } = this.state
    return shouldShow ? (
      <FpHelloBar
        data-testid="fp-hellobar"
        loading={loading}
        isPositive={isPositive}
        text={text}
      />
    ) : null
  }
}

const DeliverySlotHelloBarWrapper = props => (
  <GlobalContext.Consumer>
    {({ remoteConfig }) => (
      <CheckoutAddressConsumer>
        {({ checkoutAddress }) => (
          <DeliverySlotHelloBar
            {...props}
            checkoutAddress={checkoutAddress}
            remoteConfig={remoteConfig}
          />
        )}
      </CheckoutAddressConsumer>
    )}
  </GlobalContext.Consumer>
)

DeliverySlotHelloBarWrapper.defaultProps = {}

export default DeliverySlotHelloBarWrapper
